import { Link } from "gatsby";
import React from "react";
import * as styles from "./parts.module.scss";

interface Props {
  content: string;
  destination: string;
  clickable: boolean;
  state?: any;
  out?: boolean;
  className?: string;
}

export const Button: React.FC<Props> = ({
  content,
  destination,
  clickable,
  state,
  className = "",
  out = false,
}) => {
  return (
    <div
      className={`${className} ${styles.button} ${
        !clickable && styles.greyedOut
      }`}
    >
      <button style={{ cursor: clickable ? "pointer" : "default" }}>
        {clickable ? (
          <Link to={destination} state={state}>
            {content}
          </Link>
        ) : (
          <>{content}</>
        )}
      </button>
    </div>
  );
};
