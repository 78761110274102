import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import * as styles from "./carousel.module.scss";
// import "swiper/swiper.min.css";
// import 'swiper/css/swiper.min.css'

import "swiper/swiper.scss";
import "../../styles/globals.scss";
import * as appStyles from "../../styles/app.module.scss";
import { Button } from "../Parts/button";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const query = graphql`
  query SanityPosters{
    posters: sanityPosters {
    list {
      _key
      image {
        asset {
          gatsbyImageData(width: 420)
          url
        }
      }
    }
  }
  }
`;

interface Props {
  slideNumber?: number;
  button: string;
}

export const Carousel: React.FC<Props> = ({ slideNumber, button }) => {
  const { posters } = useStaticQuery(query);
  const [slide, setSlide] = useState<number>(0);
  const [swiper, setSwiper] = useState<any>();
  const swiperRef = useRef<any>(null);
  const images = posters.list.map((e: any) => {
    return {
      key: e._key,
      src: e.image.asset.gatsbyImageData,
      // src: e.image.asset,
    };
  });

  return (
    <div className={appStyles.carouselWrapper}>
      <div className={styles.wrapper}>
        <button
          onClick={() => swiperRef?.current.swiper.slidePrev()}
          className={styles.swiperButton}
          style={{
            visibility: swiperRef?.current?.swiper?.isBeginning
              ? "hidden"
              : "visible",
          }}
        >
          <img
            src="/arrowPrev.svg"
            alt="swiper-prev"
            className={styles.swiperButtonPrev}
          />
        </button>
        <Swiper
          slidesPerView={1.6}
          direction="horizontal"
          spaceBetween={26}
          ref={swiperRef}
          onSwiper={(swiper) => setSwiper(swiper)}
          onSlideChange={(e) => setSlide(e.activeIndex)}
          centeredSlides={true}
          initialSlide={slideNumber ? slideNumber : 0}
          className={styles.swiperContainer}
          // className="toto"
          breakpoints={{
            "@0.5": {
              slidesPerView: 1.6,
              spaceBetween: 26,
            },
            "@0.74": {
              slidesPerView: 1.6,
              // spaceBetween: 129,
            },
            "@1.30": {
              slidesPerView: 3,
              spaceBetween: 20,
            },
          }}
          // centeredSlidesBounds={true}
        >
          {images.map((image: any, index: number) => {
            return (
              <SwiperSlide key={`poster-slide-${index}`}>
                <div
                  className={`${styles.slide} ${
                    index === slide ? styles.focusShadow : styles.shadow
                  }`}
                >
                  <GatsbyImage
                    // className={`${styles.slide} ${styles.shadow}`}
                    image={image.src}
                    alt={"carouselslide"}
                  />
                </div>
                {/* <img
                  src={image.src.url}
                  className={`${styles.slide} ${
                    index === slide ? styles.focusShadow : styles.shadow
                  }`}
                /> */}
              </SwiperSlide>
            );
          })}
        </Swiper>
        <button
          onClick={() => swiperRef.current.swiper.slideNext()}
          className={styles.swiperButton}
          style={{
            visibility: swiperRef?.current?.swiper?.isEnd
              ? "hidden"
              : "visible",
          }}
        >
          <img
            src="/arrowNext.svg"
            alt="swiper-next"
            className={styles.swiperButtonNext}
          />
        </button>
      </div>
      <Button
        content={button}
        destination="/message"
        clickable={true}
        state={{ poster: images[slide].key, slide }}
      />
    </div>
  );
};
