import { graphql } from "gatsby";
import React from "react";
import { LayoutApp } from "../components/Layout/layoutApp";
import SEO from "../components/seo";
import { Carousel } from "../components/Carousel/carousel";
import { Location } from "./message";
import { usePageContext } from "../components/Context/pageContext";

const Visual: React.FC<Location & any> = ({ location, data: { visual } }) => {
  const { lang } = usePageContext();
  return (
    <>
      <LayoutApp title={visual.content[lang]} step={1}>
        <SEO title="Visuel" />
        <Carousel
          slideNumber={location.state?.slide}
          button={visual.button[lang]}
        />
      </LayoutApp>
    </>
  );
};

export const query = graphql`
  query visual {
    visual: sanityApp {
      button: visualButton {
        en
        fr
      }
      content: visualContent {
        en
        fr
      }
    }
  }
`;

export default Visual;
